import {createBrowserRouter} from "react-router-dom";
import App from "../../App";
import {PrivacyPolicy} from "../../pages/privacy-policy/PrivacyPolicy";
import {SurveySignup} from "../../pages/survey-signup/SurveySignup";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/survey-signup",
    element: <SurveySignup />,
  },
]);

export {router};
