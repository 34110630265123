import "./survey-signup.css";

function SurveySignup() {
  return (
    <div className="survey-signup">
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2_BbO3ppWfWdjhGQ9WQO79yqss7qMtBiiimy-n8a32r2AZw1UWfFz4cVpNFWtZZ7HtuHK15_-n?gv=true"
        style={{border: 0}}
        width="100%"
        height="600"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export {SurveySignup};
